<template>
  <div>
    <button
      type="button"
      class="flex items-center text-base font-light text-grey-dark-02"
      @click="isModalOpen = true"
    >
      <SpriteSymbol name="globe" class="relative h-32 w-32" />
      {{ buttonLabel }}
    </button>
    <Transition appear name="page-modal">
      <Selector
        v-if="isModalOpen"
        :current-language="currentLanguageCode"
        :current-country="currentCountryCode"
        @close="isModalOpen = false"
      />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
const Selector = defineAsyncComponent(() => import('./Selector.vue'))

const props = defineProps<{
  /**
   * The label of the current country.
   *
   * We want to avoid calling useLocaleData(), because that would load a
   * large list of all countries on all pages. And we only need to show the
   * label of the current country in the footer.
   */
  currentCountryLabel?: string
}>()

const currentLanguageCode = useCurrentLanguage()
const currentCountryCode = useCurrentCountry()
const isModalOpen = ref(false)

const buttonLabel = computed(
  () =>
    `${
      props.currentCountryLabel || currentCountryCode.value
    }, ${currentLanguageCode.value.toUpperCase()}`,
)
</script>
