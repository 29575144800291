import { defineNuxtPlugin } from '#app/nuxt'
import { LazyRokkaImage, LazyBlokkliField, LazyBlokkliEditable, LazyBlokkliProvider, LazyBlokkliItem, LazyVuepalLocalTasks, LazyVuepalAdminToolbar, LazySpriteSymbol, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["RokkaImage", LazyRokkaImage],
["BlokkliField", LazyBlokkliField],
["BlokkliEditable", LazyBlokkliEditable],
["BlokkliProvider", LazyBlokkliProvider],
["BlokkliItem", LazyBlokkliItem],
["VuepalLocalTasks", LazyVuepalLocalTasks],
["VuepalAdminToolbar", LazyVuepalAdminToolbar],
["SpriteSymbol", LazySpriteSymbol],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
