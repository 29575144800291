<template>
  <ul class="flex flex-wrap gap-24">
    <li v-for="(item, index) in items" :key="index">
      <a :href="item.href" target="_blank" class="block" :title="item.title">
        <SpriteSymbol
          :name="item.icon"
          class="h-[22px] w-[22px] fill-grey-dark-02 text-grey-dark-02 hover:fill-black hover:text-black"
        />
      </a>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { type FooterConfigFragment } from '#graphql-operations'
import { falsy } from '~/helpers/type'

const props = withDefaults(
  defineProps<{
    links?: FooterConfigFragment['socialMediaLinks']
  }>(),
  {
    links: () => [],
  },
)

const items = computed(() =>
  props.links
    .map((link) => {
      const href = link.uri?.path
      if (!href) {
        return null
      }
      const icon = getIconName(href)
      if (!icon) {
        return null
      }
      return {
        href,
        title: link.title || icon,
        icon,
      }
    })
    .filter(falsy),
)

function getIconName(url = '') {
  if (url.includes('facebook.com')) {
    return 'facebook'
  } else if (url.includes('instagram.com')) {
    return 'instagram'
  } else if (url.includes('twitter.com') || url.includes('x.com')) {
    return 'twitter'
  } else if (url.includes('youtube.com')) {
    return 'youtube'
  } else if (url.includes('pinterest.com')) {
    return 'pinterest'
  } else if (url.includes('weibo.com')) {
    return 'weibo'
  } else if (url.includes('youku.com')) {
    return 'youku'
  }
}
</script>
