import { type WatchCollectionTeaserFragment } from '#graphql-operations'
import { falsy, narrowTypeByProperty } from '~/helpers/type'

type UseWatchCollectionData = {
  /**
   * The watch collection data.
   */
  collections: ComputedRef<WatchCollectionTeaserFragment[]>
}

/**
 * Loads the watch collection data from Drupal and returns a computed property with all collections.
 */
export async function useWatchCollectionData(): Promise<UseWatchCollectionData> {
  // Store the data in global state.
  const data = useState<WatchCollectionTeaserFragment[] | null>(
    'watchCollectionData',
    () => null,
  )

  // Only fetch the data if not already done.
  if (!data.value) {
    const items = await useCachedGraphqlQuery('watchCollectionData').then(
      (v) => v.data.entityQuery.items || [],
    )
    data.value = items.map((v) => narrowTypeByProperty(v, 'id')).filter(falsy)
  }

  const collections = computed<WatchCollectionTeaserFragment[]>(
    () => data.value!,
  )

  return { collections }
}

/**
 * Builds the background gradient image URL.
 */
export function buildWatchCollectionBackground(
  v: WatchCollectionTeaserFragment['backgroundImage'] | undefined,
): string | undefined {
  const hash = v?.image?.entity?.rokkaMetadata?.hash
  if (!hash) {
    return
  }

  const config = useRuntimeConfig()
  // Use dynamic operation to create a small size gradient image.
  return `https://${config.public.rokkaHost}/dynamic/resize-width-5-height-30/${hash}.png`
}
