import { defineEasyTextsLoader } from '#nuxt-easy-texts/types'
import type { LoadTranslationsQuery } from '#build/graphql-operations'

// should be import GraphqlResponse from "#graphql-middleware-server-options-build"
// check with https://gitlab.liip.ch/bazinga/bs.ch/-/blob/develop/frontend/app/easyTexts.loader.ts?ref_type=heads
type GraphqlResponse<T> = {
  data: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any[]
}

function getTranslations(
  v?: LoadTranslationsQuery,
): Record<string, string | [string, string]> {
  if (!v) {
    return {}
  }
  return Object.entries(
    (v.translations || {}) as Record<
      string,
      string | { singular?: string; plural?: string }
    >,
  ).reduce<Record<string, string | [string, string]>>(
    (acc, [fullKey, value]) => {
      const keyWithDots = fullKey.replace('__', '.')
      if (typeof value === 'string') {
        acc[keyWithDots] = value
      } else if (typeof value === 'object' && value.plural && value.singular) {
        acc[keyWithDots] = [value.singular, value.plural]
      }
      return acc
    },
    {},
  )
}

export default defineEasyTextsLoader(() => {
  const language = useCurrentLanguage()
  const event = useRequestEvent()
  return {
    async load() {
      const { value: cachedData, addToCache } = await useDataCache<
        GraphqlResponse<LoadTranslationsQuery>
      >('translations-' + language.value, event)

      if (cachedData) {
        return getTranslations(cachedData.data)
      }

      const data = await useGraphqlQuery({
        name: 'loadTranslations',
        fetchOptions: {
          params: {
            __language_context: language.value,
          },
        },
      })

      if (data) {
        await addToCache(data)
      }

      return getTranslations(data.data)
    },
    reloadTrigger() {
      return computed(() => language.value)
    },
  }
})
