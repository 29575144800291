<template>
  <NuxtLink
    v-if="component === 'NuxtLink'"
    v-bind="componentProps"
    class="cursor-pointer"
    @click="onClick"
  >
    <slot :is-external="shouldOpenInNewWindow"></slot>
  </NuxtLink>
  <component
    :is="component"
    v-else
    v-bind="componentProps"
    class="cursor-pointer"
    @click="onClick"
  >
    <slot :is-external="shouldOpenInNewWindow"></slot>
  </component>
</template>

<script lang="ts" setup>
import { type RouteLocationRaw } from 'vue-router'
import { getLocaleFromPath } from '~/helpers/locale'

// Filetypes that should open in a new tab.
const EXTERNAL_URL_EXTENSIONS = ['jpg', 'png', 'svg', 'pdf']

function getUrlExtension(url: string): string {
  try {
    const parts = url.split(/[#?]/)
    return parts[0].split('.').pop()?.trim() || ''
  } catch (_e) {
    return ''
  }
}

const props = defineProps<{
  to?: string | RouteLocationRaw
  target?: string
  isLink?: boolean
}>()

const emit = defineEmits(['click'])

const router = useRouter()

const href = computed(() => {
  if (typeof props.to !== 'string') {
    return
  }

  if (props.to.startsWith('www')) {
    return 'https://' + props.to
  }

  return props.to
})

const component = computed(() => {
  return props.to ? 'NuxtLink' : 'div'
})

const urlExtension = computed(() => {
  return href.value ? getUrlExtension(href.value).toLowerCase() : ''
})

const shouldOpenInNewWindow = computed(
  () =>
    href.value?.startsWith('http') ||
    EXTERNAL_URL_EXTENSIONS.includes(urlExtension.value),
)

const componentProps = computed(() => {
  const componentProps: {
    [key: string]: string | string[] | RouteLocationRaw
    class: string[]
  } = {
    class: [],
  }
  if (href.value) {
    componentProps.href = href.value

    if (shouldOpenInNewWindow.value) {
      componentProps.target = '_blank'
    }
  }

  if (props.isLink) {
    componentProps.class.push('link')
    componentProps.class.push(
      shouldOpenInNewWindow.value ? 'is-external' : 'is-internal',
    )
  }

  if (typeof props.to === 'object') {
    componentProps.to = props.to
  }

  return componentProps
})

function onClick(e: Event) {
  emit('click', e)

  if (e.defaultPrevented) {
    return
  }

  // no href only @click nothing todo.
  if (!href.value) {
    return
  }

  if (shouldOpenInNewWindow.value) {
    // open in a new tab.
    return
  }

  // If the link points to a different prefix, we trigger a full page load.
  const targetLocale = getLocaleFromPath(href.value)
  const currentLocale = getLocaleFromPath(window.location.pathname)
  if (
    currentLocale.country !== targetLocale.country ||
    currentLocale.langcode !== targetLocale.langcode
  ) {
    return
  }

  // we handle the routing from here
  e.preventDefault()

  if (router.currentRoute.value.fullPath === href.value) {
    // router.push would otherwise do nothing if we click on a link which points to the current url.
    // This gives feedback to the user AND insures route change hooks are called.
    // Needed for example in /frontend/components/Page/Footer/MainLinks/index.vue for router.afterEach
    window.location.href = href.value
  } else {
    router.push(href.value)
  }
}
</script>
