
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'figma-arrow-left'
  | 'figma-arrow-right'
  | 'figma-arrow-top-right'
  | 'figma-check'
  | 'figma-play'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'bell'
  | 'card'
  | 'cart'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'close'
  | 'credit-cards'
  | 'delivery-truck'
  | 'download'
  | 'external'
  | 'facebook'
  | 'filter'
  | 'gift'
  | 'globe'
  | 'home'
  | 'instagram'
  | 'location'
  | 'logo-no-claim'
  | 'logo-shield'
  | 'logo'
  | 'mail'
  | 'menu'
  | 'minus'
  | 'phone'
  | 'pinterest'
  | 'play'
  | 'plus'
  | 'search'
  | 'settings'
  | 'shipping'
  | 'shopping-bag'
  | 'store'
  | 'twitter'
  | 'user'
  | 'volume-medium-line-icon'
  | 'volume-muted-line-icon'
  | 'watch'
  | 'weibo'
  | 'youku'
  | 'youtube'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.zTlMnVIuix.svg"}
