import type {
  CountryFragment,
  LanguageFragment,
  LocaleDataQuery,
} from '#graphql-operations'
import { getLocaleFromPath } from '@/helpers/locale'

type LocaleData = {
  languages: LanguageFragment[]
  countries: CountryFragment[]
}

/**
 * Return the current country code.
 */
export function useCurrentCountry(): ComputedRef<string> {
  const route = useRoute()

  // Overriden by the blokkli feature for easy country preview.
  const countryOverride = useState<string | null>(
    'blokkli:countryOverride',
    () => null,
  )

  return computed<string>(() => {
    if (countryOverride.value) {
      return countryOverride.value
    }
    const parts = getLocaleFromPath(route.path)
    if (parts.country) {
      return parts.country
    }

    return 'US'
  })
}

/**
 * Return the current language.
 */
export function useCurrentLanguage(): ComputedRef<string> {
  const route = useRoute()

  return computed<string>(() => {
    const parts = getLocaleFromPath(route.path)
    if (parts.langcode) {
      return parts.langcode
    }

    return 'en'
  })
}

/**
 * Get the locale data.
 *
 * This contains all available countries and languages, with labels in the
 * current language.
 *
 * Calling this composable should only be done when really needed, as the data
 * is quite large.
 */
export async function useLocaleData(): Promise<ComputedRef<LocaleData>> {
  const isLoaded = useState('localeDataLoaded', () => false)
  const data = useState<LocaleDataQuery | null>('localeData', () => null)

  if (!isLoaded.value) {
    isLoaded.value = true
    // This will only fetch it once.
    data.value = await useCachedGraphqlQuery('localeData').then((v) => v.data)
  }

  const localeData = computed(() => ({
    languages: data.value?.languages || [],
    countries: data.value?.countries || [],
  }))

  return localeData
}

type LanguageLink = {
  active: boolean
  code: string
  path?: string
}

/**
 * Get the computed page language links.
 */
export async function usePageLanguageLinks(): Promise<
  ComputedRef<LanguageLink[]>
> {
  const { data } = await useInitData()
  const currentLanguage = useCurrentLanguage()
  const currentCountry = useCurrentCountry()
  const route = useRoute()

  // Because all our URLs are non-translatable, the path is always the same
  // for all languages. We only have to replace the prefix.
  return computed(() => {
    // The current prefix.
    const prefix =
      (Array.isArray(route.params.prefix)
        ? route.params.prefix[0]
        : route.params.prefix) ||
      `${currentLanguage.value}-${currentCountry.value}`

    // Generate a link for every supported langcode.
    return data.value.langcodes.map((code) => {
      const newPrefix = `${code}-${currentCountry.value}`
      return {
        active: code === currentLanguage.value,
        code,
        path: route.path.replace('/' + prefix, '/' + newPrefix),
      }
    })
  })
}

export function useCurrentPrefix(): ComputedRef<string> {
  const currentLanguage = useCurrentLanguage()
  const currentCountry = useCurrentCountry()

  return computed<string>(
    () => `${currentLanguage.value}-${currentCountry.value}`,
  )
}
