<template>
  <div class="border-t border-t-grey-light-01 p-16">
    <NewsletterSubscription />
    <SocialMediaLinks v-if="links.length" :links="links" class="mt-28" />
    <p v-if="claim" class="mt-24">
      {{ claim }}
    </p>
  </div>
</template>

<script lang="ts" setup>
const { data } = await useInitData()

const links = computed(() => data.value.footerConfig.socialMediaLinks || [])

const claim = computed(() => data.value.footerConfig.tagline)
</script>
