type DrupalUser = {
  id: string
  name: string
  canAccessToolbar: boolean
  hasSession: boolean
}

export default async function (): Promise<ComputedRef<DrupalUser>> {
  const drupalUserFetched = useState('drupalUserFetched', () => false)
  const drupalUserData = useState<DrupalUser>('drupalUser', () => {
    return {
      id: '',
      name: '',
      canAccessToolbar: false,
      hasSession: true,
    }
  })

  const drupalUser = computed(() => drupalUserData.value)

  // If we end up here on the server, the user is definitely anonymous and has
  // no session. This is because if the request comes in with a SSESS cookie
  // (meaning there is a session), the request is never handled with SSR (which
  // is where this code is executed)
  if (process.server) {
    drupalUserData.value.hasSession = false
    drupalUserFetched.value = true
    return drupalUser
  }

  // Only executed client side.
  // If we skipped SSR, this value is going to be false, so we make the request to load the user.
  if (!drupalUserFetched.value) {
    drupalUserData.value = await useGraphqlQuery('drupalUser').then((v) => {
      return {
        id: v.data.currentDrupalUser?.id || '',
        name: v.data.currentDrupalUser?.name || '',
        canAccessToolbar: !!v.data.currentDrupalUser?.canAccessToolbar,
        hasSession: true,
      }
    })

    drupalUserFetched.value = true
  }

  return drupalUser
}
