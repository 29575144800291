
export const DPR = ["","1.5","2"]

export const VIEWPORTS = {
  'fallback': 0,
  'sm': 0,
  'md': 768,
  'lg': 1024,
  'xl': 1200,
  'xxl': 1440,
  'xxxl': 2496
} as const

export type Viewport = keyof typeof VIEWPORTS