<template>
  <div>
    <div
      :class="{
        'hover:border-b-grey-mid': disabled,
        'border-b-[#BF342D]': error,
        'mb-4': error,
      }"
      class="flex h-40 border-b border-b-grey-mid text-grey-dark-02 focus-within:border-b-grey-dark-01 focus-within:text-black hover:border-b-grey-dark-02 hover:text-black"
    >
      <SpriteSymbol
        v-if="iconStart"
        class="mr-10 mt-8 h-24 w-28"
        :name="iconStart"
      />
      <input
        v-model="value"
        :disabled="disabled"
        :name="name"
        :type="type"
        :required="required"
        :class="{
          'bg-white': disabled,
          'placeholder:text-grey-mid': disabled,
        }"
        class="focus:placeholder:text-opacity-0 grow bg-transparent placeholder:text-grey-dark-02 focus:outline-none"
        :placeholder="placeholder || title"
        :aria-label="title"
      />
      <SpriteSymbol
        v-if="iconEnd"
        class="ml-10 mt-8 h-24 w-28"
        :class="{ 'text-grey-mid': disabled }"
        :name="iconEnd"
      />
      <slot></slot>
    </div>
    <label v-if="error" class="text-xs text-[#BA251E]">{{ error }}</label>
  </div>
</template>

<script setup lang="ts">
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'
withDefaults(
  defineProps<{
    iconStart?: NuxtSvgSpriteSymbol
    iconEnd?: NuxtSvgSpriteSymbol
    title: string
    name?: string
    type?: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    error?: string
  }>(),
  {
    iconStart: undefined,
    iconEnd: undefined,
    disabled: false,
    required: false,
    error: undefined,
    placeholder: undefined,
    name: undefined,
    type: 'text',
  },
)

const value = defineModel<string>()
</script>
