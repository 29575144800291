import { useKeyModifier } from '@vueuse/core'

const isMetaPressed = useKeyModifier('Meta')

export default defineNuxtRouteMiddleware((to) => {
  if (isMetaPressed.value) {
    // If cmd is pressed, open in new tab and abort normal navigation.
    navigateTo(
      { path: to.path, query: to.query },
      { open: { target: '_blank' } },
    )

    return abortNavigation()
  }
})
