import { useMediaQuery } from '@vueuse/core'

/**
 * Checks if the user can hover. Mainly differentiate touch and mouse environments.
 */
export default function () {
  // code from https://dev.to/niorad/detecting-hover-and-touch-in-css-and-js-4e42 and https://vueuse.org/core/useMediaQuery/
  const userCanHover = computed(() => useMediaQuery('(hover: hover)').value)

  return { userCanHover }
}
