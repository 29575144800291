<template>
  <div
    v-if="messages.length"
    class="fixed left-0 top-0 z-[9999999] w-full bg-grey-dark-01 text-white"
  >
    <div
      v-for="(message, i) in messages"
      :key="i"
      class="flex items-center justify-between rounded p-24 font-medium"
      :class="classes[message.type]"
      @click="removeMessage(i)"
    >
      <div class="rich-text" v-html="message.message" />
      <SpriteSymbol name="close" class="h-24 w-24" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DrupalMessageType } from '~/composables/useDrupalMessages'

const { messages, removeMessage } = useDrupalMessages()

const classes: Record<DrupalMessageType, string> = {
  error: 'bg-red-100 text-red-800',
  warning: 'bg-blue-100 text-blue-800',
  status: 'bg-green-100 text-green-800',
}
</script>
