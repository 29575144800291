<template>
  <OrisSwiper
    :modules="[Scrollbar, FreeMode]"
    free-mode
    slides-per-view="auto"
    :centered-slides="false"
    :space-between="isMobile ? 8 : 24"
    :scrollbar="{
      enabled: true,
      snapOnRelease: false,
      draggable: true,
      horizontalClass: 'swiper-scrollbar',
    }"
    class="!overflow-visible"
    :scrollbar-margin-top="32"
  >
    <SwiperSlide v-for="item in collections" :key="item.id">
      <CollectionTeaser v-bind="item" class="w-[159px] md:w-[306px]" />
    </SwiperSlide>
    <SwiperSlide v-if="isMobileOrTablet" class="w-0" />
  </OrisSwiper>
</template>

<script lang="ts" setup>
import { SwiperSlide } from 'swiper/vue'
import { Scrollbar, FreeMode } from 'swiper/modules'

const { collections } = await useWatchCollectionData()
const { isMobile, isMobileOrTablet } = useViewport()
</script>
