import type {
  CommercePriceFragment,
  PageProductVariationGiftFragment,
  PageProductVariationGiveawayFragment,
  PageProductVariationStrapFragment,
  PageProductVariationToolFragment,
  PageProductVariationWatchFragment,
  ProductFinderItemFragment,
} from '#build/graphql-operations'
import { runtimeConfig } from '@/nuxtConfig/runtimeConfig'
import type { AnyProductVariationTeaser } from '~/types/product'

namespace AnalyticsEvent {
  export type ItemCategory =
    | 'Watch'
    | 'Strap'
    | 'Tool'
    | 'Giveaway'
    | 'Spare_part'
    | 'Gift'

  export const consentDefault = [
    'consent',
    'default',
    {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied',
    },
  ] as const

  export type ConsentDefault = typeof consentDefault

  export type PageView = {
    country: string
    language: string
    login_status: string
    user_id: string
    page_type: string
    page_category: string
  }

  export type EcommerceReset = {
    ecommerce: null
  }

  export type EcommerceProductClick = {
    event: 'select_item'
    ecommerce: {
      items: [
        {
          item_category: ItemCategory
          item_id: string
          item_name: string
          currency: string
          price: number
          quantity: 1 // It does not make sense to click more than one item at a time right?
        },
      ]
    }
  }

  export type EcommerceProductView = Omit<EcommerceProductClick, 'event'> & {
    event: 'view_item'
  }

  export type EcommerceAddToCart = Omit<EcommerceProductClick, 'event'> & {
    event: 'add_to_cart'
    ecommerce: {
      items: [
        {
          quantity: number
        },
      ]
    }
  }
}

type AnalyticsEvent =
  | AnalyticsEvent.ConsentDefault
  | AnalyticsEvent.PageView
  | AnalyticsEvent.EcommerceReset
  | AnalyticsEvent.EcommerceProductClick
  | AnalyticsEvent.EcommerceProductView
  | AnalyticsEvent.EcommerceAddToCart

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pushToDataLayerDirectly(event: any) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}

export default function () {
  const gtm = useGtm()
  const waitForConsentEvents = useState<AnalyticsEvent[]>(() => [])

  function push(event: AnalyticsEvent, force: boolean = false) {
    if (process.browser) {
      if ((gtm && gtm.enabled()) || force) {
        // if something goes wrong with the gtm plugin we just push to the dataLayer variable directly
        if (gtm) {
          gtm.push(event)
        } else {
          if (runtimeConfig.public.gtm.debug) {
            // eslint-disable-next-line no-console
            console.log('Pushing directly to dataLayer: ', event)
          }
          pushToDataLayerDirectly(event)
        }
      } else {
        // queue events till user accepts cookies
        waitForConsentEvents.value.push(event)
      }
    }
  }

  function pushWaitForConsentEvents() {
    if (process.browser) {
      const tmp = [...waitForConsentEvents.value]
      waitForConsentEvents.value = []
      for (let index = 0; index < tmp.length; index++) {
        push(tmp[index])
      }
    }
  }

  function setDefaultConsent() {
    push(AnalyticsEvent.consentDefault, true)
  }

  function parseCurrency(
    price: string | CommercePriceFragment | undefined,
  ): string {
    if (price) {
      if (typeof price === 'string') {
        return price.match(/^[^0-9]{1,}|[^0-9]{1,}$/)?.[0].trim() ?? ''
      } else {
        return price.currencyCode ?? ''
      }
    } else {
      return ''
    }
  }

  function parsePriceNumber(
    price: string | CommercePriceFragment | undefined,
  ): number {
    let finalPrice = 0
    if (price) {
      if (typeof price === 'string') {
        const currency = parseCurrency(price)
        if (currency !== '') {
          // remove currency
          let tmp = price.replace(currency, '').trim()
          // remove thousands and decimal separators
          tmp = tmp.replace(/[^0-9]/, '')
          // add decimal separator
          tmp = tmp.replace(/([0-9]{2})$/, '.$1')
          finalPrice = parseFloat(tmp)
        }
      } else {
        finalPrice = price.number ?? 0
      }
    }

    return finalPrice
  }

  async function trackPageView() {
    const route = useRoute()
    const language = useCurrentLanguage()
    const country = useCurrentCountry()
    const drupalUser = await useDrupalUser()

    const event: AnalyticsEvent.PageView = {
      page_type: route.name?.toString() || '',
      page_category: route.path.split('/').join(' / '),
      language: language.value,
      country: country.value.toLowerCase(),
      user_id: drupalUser.value.id,
      login_status: drupalUser.value.id.length ? 'true' : 'undefined',
    }

    push(event)
  }

  function trackProductClick(
    variation: AnyProductVariationTeaser | ProductFinderItemFragment,
    itemCategory: AnalyticsEvent.ItemCategory,
  ) {
    push({ ecommerce: null }) // Clear the previous ecommerce object.
    push({
      event: 'select_item' as const,
      ecommerce: {
        items: [
          {
            item_category: itemCategory,
            item_id: variation.id ?? '',
            item_name: variation.title ?? '',
            currency: parseCurrency(variation.price),
            price: parsePriceNumber(variation.price),
            quantity: 1 as const,
          },
        ],
      },
    })
  }

  function trackProductView(
    variation:
      | PageProductVariationWatchFragment
      | PageProductVariationStrapFragment,
  ) {
    push({ ecommerce: null }) // Clear the previous ecommerce object.
    push({
      event: 'view_item' as const,
      ecommerce: {
        items: [
          {
            currency: parseCurrency(variation.price),
            item_category: itemCategoryFromTypename(variation.__typename),
            item_id: variation.id ?? '',
            item_name: variation.title ?? '',
            price: parsePriceNumber(variation.price),
            quantity: 1,
          },
        ],
      },
    })
  }

  function itemCategoryFromTypename(
    typename:
      | 'CommerceProductVariationGiftDefault'
      | 'CommerceProductVariationGiveaway'
      | 'CommerceProductVariationSparePart'
      | 'CommerceProductVariationToolDefault'
      | 'CommerceProductVariationWatchDefault'
      | 'CommerceProductVariationStrapDefault',
  ) {
    switch (typename) {
      case 'CommerceProductVariationGiftDefault':
        return 'Gift'
      case 'CommerceProductVariationGiveaway':
        return 'Giveaway'
      case 'CommerceProductVariationSparePart':
        return 'Spare_part'
      case 'CommerceProductVariationToolDefault':
        return 'Tool'
      case 'CommerceProductVariationWatchDefault':
        return 'Watch'
      case 'CommerceProductVariationStrapDefault':
        return 'Strap'
    }
  }

  function trackAddToCart(
    variation:
      | PageProductVariationGiftFragment
      | PageProductVariationGiveawayFragment
      | PageProductVariationToolFragment
      | PageProductVariationWatchFragment
      | PageProductVariationStrapFragment,
  ) {
    push({ ecommerce: null }) // Clear the previous ecommerce object.
    push({
      event: 'add_to_cart' as const,
      ecommerce: {
        items: [
          {
            item_category: itemCategoryFromTypename(variation.__typename),
            item_id: variation.id ?? '',
            item_name: variation.title ?? '',
            currency: parseCurrency(variation.price),
            price: parsePriceNumber(variation.price),
            quantity: 1,
          },
        ],
      },
    })
  }

  return {
    trackPageView,
    setDefaultConsent,
    trackProductClick,
    trackProductView,
    trackAddToCart,
    pushWaitForConsentEvents,
  }
}
