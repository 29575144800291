import { useWindowSize } from '@vueuse/core'
import tailwindConfig from '~/tailwind.config'

type Viewport =
  /**
   * Mobile.
   */
  | 'sm'

  /**
   * Tablet.
   */
  | 'md'

  /**
   * Desktop.
   */
  | 'lg'

  /**
   * Widescreen.
   */
  | 'xl'

  /**
   * FullHD.
   */
  | 'xxl'

  /**
   * 4K.
   */
  | 'xxxl'

type UseViewport = {
  /**
   * Whether the current viewport is mobile.
   */
  isMobile: ComputedRef<boolean>

  /**
   * Whether the current viewport is mobile or tablet.
   */
  isMobileOrTablet: ComputedRef<boolean>

  /**
   * The current viewport ID.
   */
  viewport: ComputedRef<Viewport>

  /**
   * Reactively check if the viewport is one of the given viewport IDs.
   *
   * @example
   * const shouldRenderSlider = computed(() => isViewport('xxl', 'xl', 'lg'))
   */
  isViewport: (...viewports: Viewport[]) => boolean
}

export default function (): UseViewport {
  const windowSize = useWindowSize()

  const viewport = computed<Viewport>(() => {
    if (windowSize.width.value >= parseInt(tailwindConfig.theme.screens.xxxl)) {
      return 'xxxl'
    } else if (
      windowSize.width.value >= parseInt(tailwindConfig.theme.screens.xxl)
    ) {
      return 'xxl'
    } else if (
      windowSize.width.value >= parseInt(tailwindConfig.theme.screens.xl)
    ) {
      return 'xl'
    } else if (
      windowSize.width.value >= parseInt(tailwindConfig.theme.screens.lg)
    ) {
      return 'lg'
    } else if (
      windowSize.width.value >= parseInt(tailwindConfig.theme.screens.md)
    ) {
      return 'md'
    }

    return 'sm'
  })

  const isMobile = computed(() => viewport.value === 'sm')

  const isMobileOrTablet = computed(
    () => viewport.value === 'sm' || viewport.value === 'md',
  )

  const isViewport = (...viewports: Viewport[]) =>
    viewports.includes(viewport.value)

  return { isMobile, isMobileOrTablet, viewport, isViewport }
}
