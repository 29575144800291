export default defineNuxtPlugin(async () => {
  const drupalUser = await useDrupalUser()

  // We can skip loading the cart if the user doesn't have a session.
  if (!drupalUser.value.hasSession) {
    return
  }

  const { load } = useCart()
  await load()
})
