
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'figma-arrow-left': { import: () => import('/app/assets/symbols-from-figma/figma-arrow-left.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"figma-arrow-left"} },
  'figma-arrow-right': { import: () => import('/app/assets/symbols-from-figma/figma-arrow-right.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"figma-arrow-right"} },
  'figma-arrow-top-right': { import: () => import('/app/assets/symbols-from-figma/figma-arrow-top-right.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"figma-arrow-top-right"} },
  'figma-check': { import: () => import('/app/assets/symbols-from-figma/figma-check.svg?raw').then(v => v.default), attributes: {"width":"22","height":"16","viewBox":"0 0 22 16","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"figma-check"} },
  'figma-play': { import: () => import('/app/assets/symbols-from-figma/figma-play.svg?raw').then(v => v.default), attributes: {"width":"12","height":"14","viewBox":"0 0 12 14","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"figma-play"} },
  'arrow-down': { import: () => import('/app/assets/symbols/arrow-down.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-down"} },
  'arrow-left': { import: () => import('/app/assets/symbols/arrow-left.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-left"} },
  'arrow-right': { import: () => import('/app/assets/symbols/arrow-right.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-right"} },
  'arrow-up': { import: () => import('/app/assets/symbols/arrow-up.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-up"} },
  'bell': { import: () => import('/app/assets/symbols/bell.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"bell"} },
  'card': { import: () => import('/app/assets/symbols/card.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"card"} },
  'cart': { import: () => import('/app/assets/symbols/cart.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"cart"} },
  'check': { import: () => import('/app/assets/symbols/check.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"check"} },
  'chevron-down': { import: () => import('/app/assets/symbols/chevron-down.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-down"} },
  'chevron-left': { import: () => import('/app/assets/symbols/chevron-left.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-left"} },
  'chevron-right': { import: () => import('/app/assets/symbols/chevron-right.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-right"} },
  'chevron-up': { import: () => import('/app/assets/symbols/chevron-up.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chevron-up"} },
  'close': { import: () => import('/app/assets/symbols/close.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"close"} },
  'credit-cards': { import: () => import('/app/assets/symbols/credit-cards.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 32 32","fill":"none","id":"credit-cards"} },
  'delivery-truck': { import: () => import('/app/assets/symbols/delivery-truck.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 32 32","fill":"none","id":"delivery-truck"} },
  'download': { import: () => import('/app/assets/symbols/download.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"download"} },
  'external': { import: () => import('/app/assets/symbols/external.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"external"} },
  'facebook': { import: () => import('/app/assets/symbols/facebook.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","id":"facebook"} },
  'filter': { import: () => import('/app/assets/symbols/filter.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"filter"} },
  'gift': { import: () => import('/app/assets/symbols/gift.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"gift"} },
  'globe': { import: () => import('/app/assets/symbols/globe.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"globe"} },
  'home': { import: () => import('/app/assets/symbols/home.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"home"} },
  'instagram': { import: () => import('/app/assets/symbols/instagram.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24px","height":"24px","id":"instagram"} },
  'location': { import: () => import('/app/assets/symbols/location.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"location"} },
  'logo-no-claim': { import: () => import('/app/assets/symbols/logo-no-claim.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 122 34","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"logo-no-claim"} },
  'logo-shield': { import: () => import('/app/assets/symbols/logo-shield.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 30 34","fill":"none","id":"logo-shield"} },
  'logo': { import: () => import('/app/assets/symbols/logo.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 100 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"logo"} },
  'mail': { import: () => import('/app/assets/symbols/mail.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"mail"} },
  'menu': { import: () => import('/app/assets/symbols/menu.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"menu"} },
  'minus': { import: () => import('/app/assets/symbols/minus.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"minus"} },
  'phone': { import: () => import('/app/assets/symbols/phone.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"phone"} },
  'pinterest': { import: () => import('/app/assets/symbols/pinterest.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24px","height":"24px","id":"pinterest"} },
  'play': { import: () => import('/app/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"play"} },
  'plus': { import: () => import('/app/assets/symbols/plus.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"plus"} },
  'search': { import: () => import('/app/assets/symbols/search.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"search"} },
  'settings': { import: () => import('/app/assets/symbols/settings.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 26 26","fill":"none","id":"settings"} },
  'shipping': { import: () => import('/app/assets/symbols/shipping.svg?raw').then(v => v.default), attributes: {"width":"26","height":"20","viewBox":"1 0 24 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"shipping"} },
  'shopping-bag': { import: () => import('/app/assets/symbols/shopping-bag.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"shopping-bag"} },
  'store': { import: () => import('/app/assets/symbols/store.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"store"} },
  'twitter': { import: () => import('/app/assets/symbols/twitter.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 20 21","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"twitter"} },
  'user': { import: () => import('/app/assets/symbols/user.svg?raw').then(v => v.default), attributes: {"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"user"} },
  'volume-medium-line-icon': { import: () => import('/app/assets/symbols/volume-medium-line-icon.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","shape-rendering":"geometricPrecision","text-rendering":"geometricPrecision","image-rendering":"optimizeQuality","fill-rule":"evenodd","clip-rule":"evenodd","viewBox":"0 0 512 440.076","id":"volume-medium-line-icon"} },
  'volume-muted-line-icon': { import: () => import('/app/assets/symbols/volume-muted-line-icon.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","shape-rendering":"geometricPrecision","text-rendering":"geometricPrecision","image-rendering":"optimizeQuality","fill":"currentColor","fill-rule":"evenodd","clip-rule":"evenodd","viewBox":"0 0 512 387.951","id":"volume-muted-line-icon"} },
  'watch': { import: () => import('/app/assets/symbols/watch.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"watch"} },
  'weibo': { import: () => import('/app/assets/symbols/weibo.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 24 24","version":"1.1","id":"weibo"} },
  'youku': { import: () => import('/app/assets/symbols/youku.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","id":"youku"} },
  'youtube': { import: () => import('/app/assets/symbols/youtube.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24px","height":"24px","id":"youtube"} },
}
  