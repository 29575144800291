/**
 * This plugin extends the available routes.
 *
 * We have to override the plugin provided by Vuepal because our use case is
 * different: Our aliases are not translatable - they are always the same in
 * all languages and only change the language/country prefix.
 *
 * The default Vuepal plugin would create a new route for every language,
 * but since it's always the same, it results in duplicate paths.
 */
export default defineNuxtPlugin({
  async setup() {
    const router = useRouter()

    // Find all static routes.
    const staticRoutes = router
      .getRoutes()
      .filter((v) => !!v.meta.staticDrupalPage)

    const keys = staticRoutes.map((v) => v.meta.staticDrupalPage) as string[]

    if (!keys.length) {
      return
    }

    const { data } = await useInitData()

    staticRoutes.forEach((route) => {
      if (!route.name) {
        return
      }

      // Check if the route defines a staticDrupalPage key.
      const staticKey = route.meta.staticDrupalPage

      if (!staticKey || typeof staticKey !== 'string') {
        return
      }

      // Check if we have a valid alias from Drupal for this route.
      const drupalPath = data.value.staticNodes[staticKey]
      if (!drupalPath) {
        // If no alias is available, the route keeps its original path.
        return
      }

      // Remove the route.
      router.removeRoute(route.name)

      // For routes that are not just simple /:prefix/foobar, but something
      // like /:prefix/storefinder/:id?, we only want to replace the first
      // part of the path. In this example, if the static node is defined in
      // Drupal and it has an alias like /en-CH/stores-and-services/storefinder,
      // we want to resulting path to be /en-CH/stores-and-services/storefinder/:id?.
      const restOfThePath = route.path.split('/').slice(3).join('/')

      // Replaces '/en-US/foobar' with '/:prefix()/foobar'.
      let replacedPath = drupalPath.replace(/^\/([^/]+)/, '/:prefix()')

      // If there are additional parts of the path, append them to the Drupal
      // provided path.
      if (restOfThePath) {
        replacedPath += '/' + restOfThePath
      }

      // Add the route again, now with the updated Drupal path.
      const translatedRoute = {
        ...route,
        path: replacedPath,
        name: route.name?.toString(),
      }
      router.addRoute(translatedRoute)
    })
  },
})
