import type { NuxtConfig } from 'nuxt/schema'
import tailwindConfig from '../tailwind.config'
import { ROKKA_HOST } from '../helpers/env'

export const rokkaConfig: Exclude<NuxtConfig['rokka'], undefined> & {
  viewports: Record<string, number>
  stacks: { crop: string; noCrop: string }
} = {
  host: ROKKA_HOST,
  viewports: {
    sm: 0,
    ...Object.fromEntries(
      Object.entries(tailwindConfig.theme.screens).map(([key, value]) => [
        key,
        parseInt(value),
      ]),
    ),
  },
  dpr: ['', '1.5', '2'],
  stacks: {
    crop: 'fe_vuepal_crop',
    noCrop: 'fe_vuepal_no_crop',
  },
}
