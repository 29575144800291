<template>
  <div class="md:flex md:gap-24 lg:justify-end xl:gap-48">
    <div
      v-for="(item, index) in links"
      :key="index"
      class="flex-1 border-b border-b-grey-light-01 md:border-b-0 xl:min-w-[200px] xl:max-w-sm"
    >
      <DrupalLink
        :to="item.link.url?.path"
        class="text-gray-800 bg-gray-100 data-center flex w-full justify-between py-24 text-left text-lg font-light uppercase md:mb-24 md:p-0"
        :aria-controls="'accordion-body-' + index"
        :aria-expanded="activeIndex === index ? 'true' : 'false'"
        @click.capture="onClick(index, $event)"
      >
        <span class="main-links-span">{{ item.link.label }}</span>
        <SpriteSymbol
          name="plus"
          class="h-24 w-24 transition md:hidden"
          :class="{ 'rotate-45': activeIndex === index }"
        />
      </DrupalLink>

      <nav
        v-if="item.subtree && item.subtree.length"
        tabindex="-1"
        class="md:block"
        :class="{ hidden: activeIndex !== index }"
      >
        <ul class="mb-16 flex flex-col gap-8 lg:mb-0">
          <li v-for="(itemLink, itemIndex) in item.subtree" :key="itemIndex">
            <DrupalLink
              :to="itemLink?.link?.url?.path"
              class="text-base/[24px] font-light text-grey-dark-02"
            >
              {{ itemLink?.link?.label }}
            </DrupalLink>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MenuLinkTreeFirstFragment } from '#graphql-operations'

withDefaults(
  defineProps<{
    links?: MenuLinkTreeFirstFragment[]
  }>(),
  {
    links: () => [],
  },
)

const { isMobile } = useViewport()
const activeIndex = ref(-1)

const onClick = (index: number, e: MouseEvent) => {
  if (isMobile.value) {
    activeIndex.value = activeIndex.value === index ? -1 : index
    e.preventDefault()
  }
}

const router = useRouter()

router.afterEach(() => {
  activeIndex.value = -1
})
</script>

<style scoped>
.main-links-span {
  max-width: calc(100% - 24px); /* subtracked size of svg */
}
</style>
