<template>
  <div
    class="flex items-center justify-between border-b border-b-grey-light-01 pr-16 transition duration-500 ease-swing lg:hidden"
  >
    <button
      class="flex items-center gap-10 p-16 leading-none"
      @click.prevent="$emit('back')"
    >
      <SpriteSymbol name="arrow-left" class="h-24 w-24" />
      <span>{{ $texts('back', 'Back') }}</span>
    </button>
    <div class="uppercase">{{ label }}</div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  label: string
}>()

const { $texts } = useEasyTexts()

defineEmits(['back'])
</script>
