<template>
  <RenderCacheable :max-age="60 * 10" :cache-key="'footer' + cacheKey">
    <PageFooter />
  </RenderCacheable>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()
const country = useCurrentCountry()
const cacheKey = computed(() => 'app-bottom:' + language.value + country.value)
</script>
